.home-container{
    width: 100%;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.home-header{
    margin: 0;
    
}

.home-subheading{
    margin: 0;
}