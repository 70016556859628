.about-container{
    height: 600px;
    background-color: var(--primary-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-white);
    animation: name 2s timing-function delay iteration-count direction fill-mode;
}

.bio-container{
    width: 43%;
    height: 250px;
    margin-bottom: 100px;
}

.about-img{
    float: left;
    width: 250px;
    height: 250px;
    background-color: var(--primary-blue);
    border: 2px solid var(--primary-white);
    border-radius: 100%;
    shape-outside: circle();
    margin-right: 20px;
}

.bio{
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
}

.skills{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    width: 70%;
}

.skill{
    padding: 15px;
}

@media screen and (max-width: 1450px){
    .bio-container{
        width: 52%;
    }
}

@media screen and (max-width: 1200px){
    .bio-container{
        width: 60%;
    }
}

@media screen and (max-width: 1024px){
    .bio-container{
        width: 68%;
    }
}

@media screen and (max-width: 900px){
    .bio-container{
        width: 80%;
    }
}

@media screen and (max-width: 768px){
    .bio-container{
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 65%;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 0px;
    }
    
    .about-img{
        width: 175px;
        height: 175px;
    }
    
    .bio{
        text-align: center;
        height: 50%;
        margin-top: 0;
        font-size: 16px;
    }

    .skills{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: 42px;
    }

    .skill{
        flex-basis: calc(25% - 30px);
        margin-bottom: 10px;
        text-align: center;
    }
}

@media screen and (max-width: 480px){
    
    .bio{
        font-size: 14px;
    }

    .skills{
        font-size: 36px;
    }

}

@media screen and (max-width: 340px){

    .bio{
        font-size: 13px;
    }

    .skills{
        font-size: 30px;
    }

}