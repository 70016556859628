.navbar-container{
    position: fixed;
    z-index: 10;
    height: 100px;
    width: 100%;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    display: flex;
    align-items: center;
}

.nav-header{
    margin-left: 125px;
}

.nav-items{
    display: flex;
    list-style: none;
    margin-right: 125px;
    margin-left: auto;
    gap: 20px;
    padding: 0;        
}

.nav-item{
    height: 40px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    font-size: 24px;
    outline: 1px solid (--primary-white);
}

.nav-item:hover{
    background-color: var(--primary-white);
    color: var(--primary-blue);
    border-radius: 24px;
    cursor: pointer;
    
}

.hamburger-icon{
    display: none;
}

@media screen and (max-width: 850px) {
    .hamburger-icon{
        display: inline;
        cursor: pointer;
        margin-left: auto;
        margin-right: 95px;
        font-size: 26px;
    }

    .nav-header{
        margin-left: 100px;
    }

    .nav-items{
        display: none;
    }

    .nav-items-mobile{
        display: flex;
        flex-direction: column;
        list-style: none;
        position: absolute;
        margin: 0;
        top: 100px;
        right: 100px;
        padding: 25px 50px 25px 50px;
        background-color: var(--primary-white);
        color: var(--primary-blue);
        gap: 20px;
    }

    .triangle{
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 40px solid var(--primary-white);
        border-left: 40px solid transparent;
        right: 100px;
        top: 60px;
    }

    .nav-item:hover{
        background-color: var(--primary-blue);
        color: var(--primary-white);
    }
}

@media screen and (max-width: 480px) {
    
    .triangle{
        right: 50px;
    }

    .nav-items-mobile{
        right: 50px;

    }

    .hamburger-icon{
        margin-right: 45px;

    }

}

