@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;700&display=swap");

body {
  margin: 0;
  font-family: 'Barlow',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-blue);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --primary-white: #ffffff;
  --primary-blue: #282a36;
}

a{
  text-decoration: none;
  color: inherit;
}

.hr-div{
  width: 80%;
  height: 1px;
  background-color: var(--primary-white);
  margin-left: auto;
  margin-right: auto;
}