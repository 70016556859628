.footer-container{
    width: 100%;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    height: 200px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    font-size: 22px;
}

.footer-header{
    margin: 0;
}

.footer-socials{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    gap: 30px;
}

.em:hover{
    color: rgb(255, 87, 151);
}

.gh:hover{
    color: green;
}

.ld:hover{
    color: #0077B5;
}

.rm:hover{
    color: #ff9900;
}

.fname:hover{
    color: #006747;
    cursor: default;
}

.lname:hover{
    color: #FFC20F;
    cursor: default;
}