.projects-container{
    height: 2400px;
    color: var(--primary-white);
    background-color: var(--primary-blue); 
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.projects-header{
    text-align: center;
}

.project-right{
    width:  70%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    gap: 10px;
}

.project-left{
    width:  70%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    gap: 10px;
}

.project-slide-right{
    animation: slideRight 1.5s ease-in-out forwards;
    opacity: 1;
}

.project-slide-left{
    animation: slideLeft 1.5s ease-in-out forwards;
    opacity: 1;
}

@keyframes slideLeft {
    from {
      opacity: 0;
      transform: translateX(-175px);
    }
    to {
      opacity: 1; 
      transform: translateX(0%); 
    }
  }

  
@keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(175px);
    }
    to {
      opacity: 1; 
      transform: translateX(0%); 
    }
  }

.project-img{
    width: 50%;
    aspect-ratio: 16/10;
    border: 2px solid var(--primary-white);
}

.project-info{
    width: 30%;
}

.project-info-title{
    font-size: 32px;
}

.project-info-descr{
    font-size: 18px;
}

.project-info-techs{
    font-size: 18px;
}

.project-live-button{
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-white);
    color: var(--primary-blue);
    border: 2px solid transparent;
}

.project-live-button:hover{
    background-color: var(--primary-blue);
    color: var(--primary-white);
    border: 2px solid var(--primary-white);
    cursor: pointer;
}

.project-info-icons{
    display: flex;
    gap: 20px;
}

.icons-right{
    justify-content: flex-end;
}

.icons-left{
    justify-content: flex-start;
}

.icon-green:hover{
    color: green;
}

.icon-red:hover{
    color: red;
}

.project-info-icon{
    font-size: 32px;
    cursor: pointer;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

@media screen and (max-width: 1200px) {

    .project-img{
        width: 60%;
    }

    .project-right{
        width:  85%;

    }
    
    .project-left{
        width:  85%;
    }

}

@media screen and (max-width: 1024px) {
    .project-right{
        width:  90%;

    }
    
    .project-left{
        width:  90%;
    }
    
    .project-info-title{
        font-size: 28px;
    }
    
    .project-info-descr{
        font-size: 16px;
    }
    
    .project-info-techs{
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .projects-container{
        height: 1700px;
    }

    .project-right{
        width:  95%;
    }
    
    .project-left{
        width:  95%;
    }

    .project-info-title{
        font-size: 22px;
    }
    
    .project-info-descr{
        font-size: 14px;
    }
    
    .project-info-techs{
        font-size: 14px;
    }
}

@media screen and (max-width: 560px) {

    .projects-container{
        height: 1200px;
    }

    .project-info-title{
        font-size: 20px;
    }
    
    .project-info-descr{
        font-size: 12px;
    }
    
    .project-info-techs{
        font-size: 11px;
    }
    .project-info-icon{
        font-size: 24px;
    }

    .project-live-button{
        width: 70px;
        height: 30px;

    }
    
}

@media screen and (max-width: 480px) {

    .project-right{
        height: 300px;
    }
    
    .project-left{
        height: 300px;
    }

    .project-info-title{
        font-size: 15px;
    }
    
    .project-info-descr{
        font-size: 10px;
    }
    
    .project-info-techs{
        font-size: 10px;
    }

    .project-live-button{
        width: 60px;
        height: 30px;
        font-size: 15px;
    }
    
}

@media screen and (max-width: 380px) {

    .projects-container{
        height: 1000px;
    }

    .project-right{
        height: 250px;
    }
    
    .project-left{
        height: 250px;
    }

    .project-info-title{
        font-size: 12px;
    }
    
    .project-info-descr{
        font-size: 9px;
    }
    
    .project-info-techs{
        font-size: 8px;
    }

    .project-live-button{
        width: 50px;
        height: 20px;
        font-size: 12px;
    }
}